
import React, { useEffect, useState } from 'react';
import axios from "axios"
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';




export default function Alerts() {
    const [user, setUser] = useState("");
    const [load, setLoad] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [hasChanged, setHasChanged] = useState(0)


    const auth = getAuth();

    useEffect(() => {
     
        onAuthStateChanged(auth, (user) => {

          if (user) {
            console.log(user.email)

           
     
            setUser(user.email)
            getData(user.email)
          } else {
            console.log("no")
          }
        });
   
      }, [hasChanged]);


const getData = async (userP) => {
    let response = await axios.post("/api/report/getreport")

    console.log(response.data)



    const subs = response.data.map((elem) => {
        console.log(elem)
        if (elem.subscribers.includes(userP)) {
            return (
             elem.id
            )
            
        }
    }).filter((ellm) => ellm !== undefined)

    setSubscriptions(subs)
    console.log(subs)

    setReportData(response.data)

    setLoad(true)


}


const subbedUsers = (id) => {
    const subs = reportData.filter((elem) => elem.id == id)
    return (
        subs.map((ellm) => {
            return (
                ellm.subscribers
            )
        })
    )
}

const subscribe = async (id) => {
    const response = await axios.post("/api/report/subscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const unsubscribe = async (id) => {
    console.log("Fire")
    const response = await axios.post("/api/report/unsubscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const createOne = async () => {
    const response = await axios.post("/api/report/create", {
        id: "FFgp"
    })
}

const userSub = (id) => {
    console.log("Fire")
    return (subscriptions.includes(id))
}


const log = () => {
    console.log(userSub("QswE"))

    console.log(subscriptions)
}


 
if (load) {


    return (
        <div style={{ marginLeft: "200px", borderRadius: "20px" }}>

        <Card variant="outlined" sx={{width: "30vw"}}>
            <CardContent>
                <Typography variant="h6" gutterBottom sx={{   }}>
                     Predictive / Actual - Products & Variant Sales
                </Typography>
                <Typography sx={{fontSize: 14}}  component="div">
                  Type: Report
                </Typography>
                <Typography variant="body2">
                Time: 9:00am Mondays
               
                </Typography>

                <Typography variant="body2">
                Subscribers: {subbedUsers("QswE").join(", ")}
               
                </Typography>
           
                </CardContent>
                <CardActions>
                {userSub("QswE") ? <Button onClick={() => unsubscribe("QswE")}>Unsubscribe</Button> : <Button onClick={() => subscribe("QswE")}>Subscribe</Button>  }
                </CardActions>
        </Card>

        <Card variant="outlined" style={{marginTop:"1vw"}} sx={{width: "30vw"}}>
            <CardContent>
                <Typography variant="h6" gutterBottom sx={{   }}>
                     Freebies
                </Typography>
                <Typography sx={{fontSize: 14}}  component="div">
                  Type: Report
                </Typography>
                <Typography variant="body2">
                Time: 9:30am Everyday
               
                </Typography>

                <Typography variant="body2">
                Subscribers: {subbedUsers("FFgp").join(", ")}
               
                </Typography>
           
                </CardContent>
                <CardActions>
                {userSub("FFgp") ? <Button onClick={() => unsubscribe("FFgp")}>Unsubscribe</Button> : <Button onClick={() => subscribe("FFgp")}>Subscribe</Button>  }
                </CardActions>
        </Card>
            
        {/* <h1>Alerts</h1>

        <h2>Report</h2>
        <h3>Single Product Inventory </h3>
        <h4>Frequency: 1st of the month, 9:00am</h4>
        <h5>Subscribers: {subbedUsers("QswE")}</h5>
        {userSub("QswE") ? <button onClick={() => unsubscribe("QswE")}>Unsubscribe</button> : <button onClick={() => subscribe("QswE")}>Subscribe</button>  }
        <button onClick={unsubscribe}>create</button>
        <button onClick={getData}>gd</button> */}
        {/* <button onClick={createOne}>Create</button> */}
        </div>
    )
} else {
    return (
        <div></div>
    )
}
}